import "core-js/actual/structured-clone";

import * as Sentry from "@sentry/react";
import { type CaptureContext } from "@team-application/loan-request-form-builder/dist/src/types/Utility.type";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import { useCallback } from "react";
import { createRoot } from "react-dom/client";
import { initReactI18next } from "react-i18next";
import "../../css/build/loanForm/style.scss";

declare global {
  interface Window {
    __LOAN_FORM_INTERNAL__: any;
	__T17__?: string;
  }
}

const translator = i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: ["de"],
    fallbackLng: "de",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      requestOptions: {
        cache: "no-store",
      },
    },
  });

Sentry.init({
    dsn: 'https://c35b0c1cd269a8bb810920e338793834@issue.kredit.de/4508008164360272',
    integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 0.5,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost'],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
    release: SENTRY_RELEASE,
    enabled: STAGE !== 'local',
    environment: STAGE,
});
const scriptElement = document.currentScript;
(async () => {
  // Wait for i18n before importing the form
  await translator;
  const LoanFormBuilder = (
    await import("@team-application/loan-request-form-builder")
  ).default;
  let rootElementId = "rootLoanSelection";
  try {
    const data = scriptElement?.getAttribute("data-root-element-id");
    if(data && typeof data === "string") rootElementId = data;
  } catch(err) {
    //
  }
  const rootElement = document.getElementById(rootElementId);
  if (!rootElement) {
    throw new Error("root element not found");
  }

  let defaultRuntime: number | undefined = 84;
  let defaultAmount: number | undefined = 20000;
  let defaultPurpose: string | undefined = "freie Verfügung";

  const runtimes = [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 240];
  const url = new URL(window.location.href);
  if (url.pathname.includes("/pk-antrag/")) {
    runtimes.push(240);
  }
  if (typeof window.__LOAN_FORM_INTERNAL__ === "object") {
    if (window?.__LOAN_FORM_INTERNAL__?.loanSelectionDefault?.credit?.runtime) {
      defaultRuntime = 
        Number(window?.__LOAN_FORM_INTERNAL__?.loanSelectionDefault?.credit?.runtime);
    }
    if (window?.__LOAN_FORM_INTERNAL__?.loanSelectionDefault?.credit?.amount) {
      defaultAmount =
        window?.__LOAN_FORM_INTERNAL__?.loanSelectionDefault?.credit?.amount;
    }
    if (window?.__LOAN_FORM_INTERNAL__?.loanSelectionDefault?.type) {
      defaultPurpose =
        window?.__LOAN_FORM_INTERNAL__?.loanSelectionDefault?.type;
    }
    delete window.__LOAN_FORM_INTERNAL__;
  }

  function LoanFormular() {
    const handleError = useCallback(
      (error: string | Error, context: CaptureContext) => {
        context.tags = context.tags || {};
        context.tags.newLoanForm = true;
        context.tags.startPage = true;

        if (error instanceof Error) {
          Sentry.captureException(error, context);
        } else {
          Sentry.captureMessage(String(error), context);
        }
      },
      []
    );

	const pathname = window.location.pathname;

    const onValid = (data: any) => {
      rootElement!.innerHTML = `
      <form action="${ pathname.startsWith("/pk-antrag/") ? "/pk-antrag/anfrage/" : "/privatkredit/anfrage/" }" method="post" id="loan-request-page-form">
          <input type="hidden" name="creditsum" value="${data.credit.amount}">
          <input type="hidden" name="year" value="${data.credit.runtime}">
          <input type="hidden" name="usage" value="${data.type}">
          <input type="hidden" name="monthlyrate" value="${data.credit.monthlyRate}">
      </form>
  `;
      document.getElementById("loan-request-page-form")?.submit();
    };

	const t17 = window.__T17__;
	const variant: string[] = [];
	if (t17 === "v1") {
		variant.push("t17");
	}

    return (
      <Sentry.ErrorBoundary
        fallback={<LoanFormBuilder.FormError showAddonInfo />}
      >
        <div className="start-page-loan-form">
			<LoanFormBuilder.LoanSelectionWrapper variant={variant}>
				<LoanFormBuilder.Kredit.LoanSelection
					onValid={onValid}
					amountDefault={defaultAmount}
					runtimeDefaultSelected={defaultRuntime}
					runtimes={runtimes}
					runtimeVariant="select"
          		/>
			</LoanFormBuilder.LoanSelectionWrapper>
        </div>
      </Sentry.ErrorBoundary>
    );
  }


  const root = createRoot(rootElement);
  root.render(<LoanFormular />);
})();
